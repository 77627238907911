import React, { useEffect, useMemo, useState } from "react";
import { IsJsonString, getFileType } from "../../../../libs/helpers";
import {
  docx,
  errorOrange,
  filePresent,
  uploadPreview,
  xlsx,
  zip,
} from "../../../../libs/images";
import { Box, Tooltip } from "@mui/material";
import VideoMessage from "./VideoMessage";
import LinkifiedText from "../linkifyText";
import { Star } from "@mui/icons-material";

function TemplateMessage({
  text,
  Id,
  statusImg,
  sentOrRecieved,
  status,
  statusHeb,
  isDateTime,
  timeFromServer,
  templatesList,
  handleMediaPreview,
  chatCustomizationSettings,
  setTemplateFileType,
  isFavourite,
  userConfig,
  onVideoMessageLoaded,
}) {
  const { doc, isDocParsed } = useMemo(() => {
    let doc = IsJsonString(text) ? JSON.parse(text) : text;
    let isDocParsed = false;
    if (doc?.components) {
      const currentTemplateInList = templatesList.templates.find(
        (item) => item.name === doc.name,
      );
      
      if (currentTemplateInList) {
        const paramsObject = JSON.parse(JSON.stringify(currentTemplateInList));

        // console.log(paramsObject);
        let newDoc = {};
        paramsObject.components.forEach((comp) => {
          if (comp.type === "BUTTONS") {
            newDoc["BUTTONS"] = {
              buttons: comp.buttons,
            };
            if (doc.components.some((item) => item.type === "button")) {
              const docBtnComp = doc.components.find(
                (item) => item.type === "button",
              );
              const urlBtn = newDoc["BUTTONS"].buttons.find(
                (btn) => btn.type === "URL",
              );
              urlBtn.url = urlBtn.url.replace(
                `{{1}}`,
                docBtnComp.parameters[0]?.text,
              );
            }
          } else if (comp.type === "HEADER") {
            newDoc["HEADER"] = {
              format: comp.format,
            };

            if (doc.components.some((item) => item.type === "HEADER")) {
              const params = doc.components.find(
                (item) => item.type === "HEADER",
              ).parameters;

              if (comp.format === "LOCATION") {
              } else if (comp.format === "DOCUMENT") {
                newDoc["HEADER"].text = params[0].document.link;
              } else if (comp.format === "IMAGE") {
                newDoc["HEADER"].text = params[0].image.link;
              } else if (comp.format === "VIDEO") {
                newDoc["HEADER"].text = params[0].video.link;
              } else {
                newDoc["HEADER"].text = comp.text;
                params.forEach((param, index) => {
                  newDoc["HEADER"].text = newDoc[comp.type].text.replace(
                    `{{${index + 1}}}`,
                    param.text,
                  );
                });
              }
            } else {
              newDoc["HEADER"].text = comp.text;
            }
          } else {
            newDoc[comp.type] = {
              text: comp.text,
            };

            if (doc.components.some((item) => item.type === comp.type)) {
              const params = doc.components.find(
                (item) => item.type === comp.type,
              ).parameters;

              params.forEach((param, index) => {
                newDoc[comp.type].text = newDoc[comp.type].text.replace(
                  `{{${index + 1}}}`,
                  param.text,
                );
              });
            }
          }
        });

        doc = newDoc;
        isDocParsed = true;
      }
    } else if (doc?.["BODY"]) {
      isDocParsed = true;
    }

    return { doc, isDocParsed };
  }, [text, templatesList?.templates]);

  useEffect(() => {
    setTemplateFileType(doc?.["HEADER"]?.format || null);
  }, [doc]);

  const returnFileTypeImg = (str) => {
    if (!str) return;
    const fileType = getFileType(str);
    let fileTypeImg;
    if (fileType === "pdf") {
      fileTypeImg = uploadPreview;
    } else if (fileType === "docx") {
      fileTypeImg = docx;
    } else if (fileType === "xlsx") {
      fileTypeImg = xlsx;
    } else if (fileType === "zip") {
      fileTypeImg = zip;
    }

    return fileTypeImg;
  };

  return (
    <>
      {doc && typeof doc === "object" ? (
        isDocParsed ? (
          <div className="manage-templates-tab__preview-content preview-content-manage-templates">
            {doc["HEADER"] && (
              <div className="preview-content-manage-templates__header">
                {doc["HEADER"].format === "TEXT" ? (
                  <Box
                    sx={{
                      color:
                        chatCustomizationSettings?.find(
                          (option) =>
                            option.type ===
                            (sentOrRecieved === "outcoming"
                              ? "outcomingMessage"
                              : "incomingMessage"),
                        )?.value === "custom"
                          ? chatCustomizationSettings?.find(
                              (option) =>
                                option.type ===
                                (sentOrRecieved === "outcoming"
                                  ? "outcomingMessage"
                                  : "incomingMessage"),
                            ).customColor.text + " !important"
                          : null,
                    }}
                  >
                    {doc["HEADER"].text}
                  </Box>
                ) : doc["HEADER"].format === "IMAGE" ? (
                  <div className="message__upload-container">
                    <div className="message__upload-preview">
                      <img src={doc["HEADER"].text} alt="" />

                      <button
                        // href={doc["HEADER"].text}
                        // target="_blank"
                        className="message__upload-preview-link"
                        // rel="noreferrer"
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          handleMediaPreview({
                            visible: true,
                            file: doc["HEADER"].text,
                            type: "image",
                          })
                        }
                      >
                        <div className="funner-icon">
                          <svg
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                            <g
                              id="SVGRepo_tracerCarrier"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></g>
                            <g id="SVGRepo_iconCarrier">
                              {" "}
                              <g id="Interface / Magnifying_Glass_Plus">
                                {" "}
                                <path
                                  id="Vector"
                                  d="M7 10H10M10 10H13M10 10V7M10 10V13M15 15L21 21M10 17C6.13401 17 3 13.866 3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10C17 13.866 13.866 17 10 17Z"
                                  stroke="#ffffff"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                ></path>{" "}
                              </g>{" "}
                            </g>
                          </svg>
                        </div>
                      </button>
                    </div>
                  </div>
                ) : doc["HEADER"].format === "VIDEO" ? (
                  // <div
                  //   className={`message__upload-container message__upload-container_video funner-global-message__upload-container-wrap_video-${Id} playing`}
                  // >
                  //   <div className="message__video video-message">
                  //     <div className="video-message__container">
                  //       <video
                  //         className={`video-message__main-video funner-global-video-message__main-video-${Id}`}
                  //         src={doc["HEADER"].text}
                  //       ></video>
                  //       <div
                  //         className={`video-message__play-container video-message__play-container-${Id}`}
                  //       >
                  //         <button
                  //           className={`video-message__play-btn funner-global-video-message__play-btn-${Id}`}
                  //         ></button>
                  //       </div>
                  //     </div>
                  //     <a
                  //       href={doc["HEADER"].text}
                  //       target="_blank"
                  //       className="video-message__link"
                  //       rel="noreferrer"
                  //     >
                  //       פתח קובץ
                  //     </a>
                  //   </div>
                  // </div>
                  <VideoMessage
                    file={doc["HEADER"].text}
                    Id={Id}
                    status={status}
                    onVideoMessageLoaded={onVideoMessageLoaded}
                  />
                ) : doc["HEADER"].format === "DOCUMENT" ? (
                  <div className="message__upload-container">
                    <div
                      className="message__upload-preview document"
                      ref={(el) => {
                        if (el?.current)
                          el.current.style.setProperty(
                            "width",
                            "100%",
                            "important",
                          );
                      }}
                    >
                      <img src={returnFileTypeImg(doc["HEADER"].text)} alt="" />
                      <a
                        style={{ cursor: "pointer" }}
                        href={
                          getFileType(doc["HEADER"].text) === "pdf"
                            ? null
                            : doc["HEADER"].text
                        }
                        target={
                          getFileType(doc["HEADER"].text) === "pdf"
                            ? null
                            : "_blank"
                        }
                        className="message__upload-preview-link"
                        rel={
                          getFileType(doc["HEADER"].text) === "pdf"
                            ? null
                            : "noreferrer"
                        }
                        onClick={() =>
                          getFileType(doc["HEADER"].text) === "pdf"
                            ? handleMediaPreview({
                                visible: true,
                                file: doc["HEADER"].text,
                                type: "pdf",
                              })
                            : null
                        }
                      >
                        <div className="funner-icon">
                          <svg
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                            <g
                              id="SVGRepo_tracerCarrier"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></g>
                            <g id="SVGRepo_iconCarrier">
                              {" "}
                              <g id="Interface / Magnifying_Glass_Plus">
                                {" "}
                                <path
                                  id="Vector"
                                  d="M7 10H10M10 10H13M10 10V7M10 10V13M15 15L21 21M10 17C6.13401 17 3 13.866 3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10C17 13.866 13.866 17 10 17Z"
                                  stroke="#ffffff"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                ></path>{" "}
                              </g>{" "}
                            </g>
                          </svg>
                        </div>
                      </a>
                    </div>
                  </div>
                ) : doc["HEADER"].format === "LOCATION" ? (
                  <p>
                    {doc["HEADER"].location?.latitude}{" "}
                    {doc["HEADER"].location?.longitude}
                    <br />
                    {doc["HEADER"].location?.name}
                    <br />
                    {doc["HEADER"].location?.address}
                  </p>
                ) : (
                  <></>
                )}
              </div>
            )}
            <Box
              sx={{
                color:
                  chatCustomizationSettings?.find(
                    (option) =>
                      option.type ===
                      (sentOrRecieved === "outcoming"
                        ? "outcomingMessage"
                        : "incomingMessage"),
                  )?.value === "custom"
                    ? chatCustomizationSettings?.find(
                        (option) =>
                          option.type ===
                          (sentOrRecieved === "outcoming"
                            ? "outcomingMessage"
                            : "incomingMessage"),
                      ).customColor.text + " !important"
                    : null,
              }}
              className="preview-content-manage-templates__text"
            >
              {doc["BODY"]?.text && (
                <LinkifiedText
                  text={doc["BODY"]?.text || ""}
                  websitePreviewObj={null}
                  funnerUsers={[]}
                  messageType={""}
                  chatCustomizationSettings={chatCustomizationSettings}
                />
              )}
            </Box>
            {doc["FOOTER"] && (
              <div className="preview-content-manage-templates__footer">
                {doc["FOOTER"].text}
              </div>
            )}
            <div className="preview-content-manage-templates__bottom">
              <div className="d-flex message-bottom">
                {
                  // statusImg === errorOrange &&
                  sentOrRecieved === "outcoming" && (
                    <Tooltip
                      title={
                        status?.toLowerCase() === "pending whatsapp" ||
                        status?.toLowerCase() === "pending system" ||
                        status?.toLowerCase() === "sending" ? (
                          "בתהליך שליחה..."
                        ) : status?.toLowerCase() === "uploading" ? (
                          <Box>
                            מעלה קובץ.
                            <br />
                            ההודעה תשלח בסיום ההעלאה.
                          </Box>
                        ) : (
                          statusHeb || status
                        )
                      }
                    >
                      <Box
                        component={"img"}
                        sx={{
                          transform:
                            status?.toLowerCase() === "pending whatsapp" ||
                            status?.toLowerCase() === "pending system" ||
                            status?.toLowerCase() === "sending" ||
                            status?.toLowerCase() === "uploading"
                              ? "scale(1.5)"
                              : null,
                          width: 16,
                        }}
                        className={`status-img ${
                          statusImg == null ? "display-none" : ""
                        }`}
                        src={statusImg}
                        alt=""
                      />
                    </Tooltip>
                    // <div className="whatsapp-status-tooltip">
                    //   <div className="whatsapp-status-tooltip__content">
                    //     <p>{statusHeb || status}</p>
                    //   </div>
                    // </div>
                  )
                }

                <Box
                  component="span"
                  className={`text-small text-muted ${
                    isDateTime ? "active" : ""
                  }`}
                  sx={{
                    color:
                      chatCustomizationSettings?.find(
                        (option) =>
                          option.type ===
                          (sentOrRecieved === "outcoming"
                            ? "outcomingMessage"
                            : "incomingMessage"),
                      )?.value === "custom"
                        ? chatCustomizationSettings?.find(
                            (option) =>
                              option.type ===
                              (sentOrRecieved === "outcoming"
                                ? "outcomingMessage"
                                : "incomingMessage"),
                          ).customColor.date + " !important"
                        : "rgba(0, 0, 0, 0.38)",
                  }}
                >
                  {/* {timeFromServer} */}
                  {userConfig.showDate
                    ? timeFromServer
                    : timeFromServer?.slice(5)}
                </Box>
                {isFavourite && (
                  <Star sx={{ fontSize: 16, color: "text.disabled" }} />
                )}
              </div>
            </div>
            {doc["BUTTONS"] && (
              <div className="preview-content-manage-templates__buttons-container">
                {doc["BUTTONS"]?.buttons?.map((button, index) => {
                  if (button.type === "URL") {
                    return (
                      <a
                        key={`${button.url}-${button.text}`}
                        className={`preview-content-manage-templates__button preview-content-manage-templates__button--${button.type.toLowerCase()}`}
                        href={button.url}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <span className="_button-text">{button.text}</span>
                        <span className="_button-icon">
                          <svg
                            width="64px"
                            height="64px"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            stroke="#000000"
                          >
                            <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                            <g
                              id="SVGRepo_tracerCarrier"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></g>
                            <g id="SVGRepo_iconCarrier">
                              <path
                                d="M11 4H4V18C4 19.1046 4.89543 20 6 20H18C19.1046 20 20 19.1046 20 18V13"
                                stroke="#1976d2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              ></path>
                              <path
                                d="M9 15L20 4"
                                stroke="#1976d2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              ></path>
                              <path
                                d="M15 4H20V9"
                                stroke="#1976d2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              ></path>
                            </g>
                          </svg>
                        </span>
                      </a>
                    );
                  } else
                    return (
                      <div
                        key={`${button.type.toLowerCase()}-${button.text}`}
                        className={`preview-content-manage-templates__button preview-content-manage-templates__button--${button.type.toLowerCase()}`}
                      >
                        <span className="_button-text">{button.text}</span>
                        <span className="_button-icon">
                          {button.type === "QUICK_REPLY" ? (
                            <svg
                              fill="#1976d2"
                              width="64px"
                              height="64px"
                              viewBox="0 0 32 32"
                              version="1.1"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                              <g
                                id="SVGRepo_tracerCarrier"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              ></g>
                              <g id="SVGRepo_iconCarrier">
                                {" "}
                                <title>reply</title>{" "}
                                <path d="M3.488 15.136q0 0.96 0.8 1.472l10.72 7.136q0.416 0.288 0.896 0.32t0.928-0.224 0.704-0.672 0.256-0.896v-3.584q3.456 0 6.208 1.984t3.872 5.152q0.64-1.792 0.64-3.552 0-2.912-1.44-5.376t-3.904-3.904-5.376-1.44v-3.584q0-0.48-0.256-0.896t-0.704-0.672-0.928-0.224-0.896 0.32l-10.72 7.136q-0.8 0.512-0.8 1.504z"></path>
                              </g>
                            </svg>
                          ) : button.type === "URL" ? (
                            <svg
                              width="64px"
                              height="64px"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              stroke="#000000"
                            >
                              <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                              <g
                                id="SVGRepo_tracerCarrier"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              ></g>
                              <g id="SVGRepo_iconCarrier">
                                <path
                                  d="M11 4H4V18C4 19.1046 4.89543 20 6 20H18C19.1046 20 20 19.1046 20 18V13"
                                  stroke="#1976d2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                ></path>
                                <path
                                  d="M9 15L20 4"
                                  stroke="#1976d2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                ></path>
                                <path
                                  d="M15 4H20V9"
                                  stroke="#1976d2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                ></path>
                              </g>
                            </svg>
                          ) : button.type === "PHONE_NUMBER" ? (
                            <svg
                              width="64px"
                              height="64px"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                              <g
                                id="SVGRepo_tracerCarrier"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              ></g>
                              <g id="SVGRepo_iconCarrier">
                                {" "}
                                <path
                                  d="M10.0376 5.31617L10.6866 6.4791C11.2723 7.52858 11.0372 8.90532 10.1147 9.8278C10.1147 9.8278 10.1147 9.8278 10.1147 9.8278C10.1146 9.82792 8.99588 10.9468 11.0245 12.9755C13.0525 15.0035 14.1714 13.8861 14.1722 13.8853C14.1722 13.8853 14.1722 13.8853 14.1722 13.8853C15.0947 12.9628 16.4714 12.7277 17.5209 13.3134L18.6838 13.9624C20.2686 14.8468 20.4557 17.0692 19.0628 18.4622C18.2258 19.2992 17.2004 19.9505 16.0669 19.9934C14.1588 20.0658 10.9183 19.5829 7.6677 16.3323C4.41713 13.0817 3.93421 9.84122 4.00655 7.93309C4.04952 6.7996 4.7008 5.77423 5.53781 4.93723C6.93076 3.54428 9.15317 3.73144 10.0376 5.31617Z"
                                  fill="#1976d2"
                                ></path>{" "}
                              </g>
                            </svg>
                          ) : (
                            //     : button.type === "COPY_CODE"
                            //     ? `
                            //   <svg width="64px" height="64px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <path fill-rule="evenodd" clip-rule="evenodd" d="M19.5 16.5L19.5 4.5L18.75 3.75H9L8.25 4.5L8.25 7.5L5.25 7.5L4.5 8.25V20.25L5.25 21H15L15.75 20.25V17.25H18.75L19.5 16.5ZM15.75 15.75L15.75 8.25L15 7.5L9.75 7.5V5.25L18 5.25V15.75H15.75ZM6 9L14.25 9L14.25 19.5L6 19.5L6 9Z" fill="#1976d2"></path> </g></svg>
                            // `
                            <></>
                          )}
                        </span>
                        {button.type === "URL" &&
                          button.url?.match(/{{(\d+)}}/g) && (
                            <div className="_button-link">
                              (url: {button.url})
                            </div>
                          )}
                      </div>
                    );
                })}
              </div>
            )}
          </div>
        ) : (
          <>
            <Box
              sx={{
                color:
                  chatCustomizationSettings?.find(
                    (option) =>
                      option.type ===
                      (sentOrRecieved === "outcoming"
                        ? "outcomingMessage"
                        : "incomingMessage"),
                  )?.value === "custom"
                    ? chatCustomizationSettings?.find(
                        (option) =>
                          option.type ===
                          (sentOrRecieved === "outcoming"
                            ? "outcomingMessage"
                            : "incomingMessage"),
                      ).customColor.text + " !important"
                    : null,
              }}
              className="whatsapp regular-text"
            >
              <LinkifiedText
                text={doc.text || ""}
                websitePreviewObj={null}
                funnerUsers={[]}
                messageType={""}
                chatCustomizationSettings={chatCustomizationSettings}
              />
            </Box>
            <div className="d-flex message-bottom">
              <img
                width="16"
                className={`status-img ${
                  statusImg == null ? "display-none" : ""
                }`}
                src={statusImg}
                alt=""
              />
              {statusImg === errorOrange && sentOrRecieved === "outcoming" && (
                <div className="whatsapp-status-tooltip">
                  <div className="whatsapp-status-tooltip__content">
                    <p>{statusHeb || status}</p>
                  </div>
                </div>
              )}

              <Box
                component={"span"}
                className={`text-small text-muted ${
                  isDateTime ? "active" : ""
                }`}
                sx={{
                  color:
                    chatCustomizationSettings?.find(
                      (option) =>
                        option.type ===
                        (sentOrRecieved === "outcoming"
                          ? "outcomingMessage"
                          : "incomingMessage"),
                    )?.value === "custom"
                      ? chatCustomizationSettings?.find(
                          (option) =>
                            option.type ===
                            (sentOrRecieved === "outcoming"
                              ? "outcomingMessage"
                              : "incomingMessage"),
                        ).customColor.date + " !important"
                      : "rgba(0, 0, 0, 0.38)",
                }}
              >
                {/* {timeFromServer} */}
                {userConfig.showDate
                  ? timeFromServer
                  : timeFromServer?.slice(5)}
              </Box>
              {isFavourite && (
                <Star sx={{ fontSize: 16, color: "text.disabled" }} />
              )}
            </div>
          </>
        )
      ) : (
        <>
          <Box
            sx={{
              color:
                chatCustomizationSettings?.find(
                  (option) =>
                    option.type ===
                    (sentOrRecieved === "outcoming"
                      ? "outcomingMessage"
                      : "incomingMessage"),
                )?.value === "custom"
                  ? chatCustomizationSettings?.find(
                      (option) =>
                        option.type ===
                        (sentOrRecieved === "outcoming"
                          ? "outcomingMessage"
                          : "incomingMessage"),
                    ).customColor.text + " !important"
                  : null,
            }}
            className="whatsapp regular-text"
          >
            {text ? text : ""}
          </Box>
          <div className="d-flex message-bottom">
            <img
              width="16"
              className={`status-img ${
                statusImg == null ? "display-none" : ""
              }`}
              src={statusImg}
              alt=""
            />
            {statusImg === errorOrange && sentOrRecieved === "outcoming" && (
              <div className="whatsapp-status-tooltip">
                <div className="whatsapp-status-tooltip__content">
                  <p>{statusHeb || status}</p>
                </div>
              </div>
            )}

            <Box
              component={"span"}
              className={`text-small text-muted ${isDateTime ? "active" : ""}`}
              sx={{
                color:
                  chatCustomizationSettings?.find(
                    (option) =>
                      option.type ===
                      (sentOrRecieved === "outcoming"
                        ? "outcomingMessage"
                        : "incomingMessage"),
                  )?.value === "custom"
                    ? chatCustomizationSettings?.find(
                        (option) =>
                          option.type ===
                          (sentOrRecieved === "outcoming"
                            ? "outcomingMessage"
                            : "incomingMessage"),
                      ).customColor.date + " !important"
                    : "rgba(0, 0, 0, 0.38)",
              }}
            >
              {/* {timeFromServer} */}
              {userConfig.showDate ? timeFromServer : timeFromServer?.slice(5)}
            </Box>
            {isFavourite && (
              <Star sx={{ fontSize: 16, color: "text.disabled" }} />
            )}
          </div>
        </>
      )}
    </>
  );
}

export default TemplateMessage;
